const DataText = state => [
	{
		id: 1,
		title: "Doorgeleiding",
		sentence:
			"Daar waar documenten zich niet onder u bevinden en/of u er mee bekend bent dat tevens bij één of meer andere bestuursorganen informatie berust met betrekking tot dit onderwerp, verzoek ik u zonder oprekken van termijnen doorgeleiding van dit verzoek naar het juiste orgaan. Mocht dat inderdaad gebeuren, dan ontvang ik daarover graag bericht."
	},
	{
		id: 2,
		title: "Mondelinge toelichting",
		sentence:
			"Mocht u vragen hebben over de precieze interpretatie of de gewenste afhandeling van dit verzoek, dan ben ik graag bereid een nadere toelichting te geven. Ik verzoek u hierover zo spoedig mogelijk maar uiterlijk binnen twee weken na ontvangst van mijn Woo-verzoek contact op te nemen."
	},
	{
		id: 3,
		title: "Reeds openbare informatie",
		sentence:
			"Mocht de door mij gevraagde informatie reeds in een voor mij gemakkelijk toegankelijke vorm openbaar beschikbaar zijn, dan verzoek ik u mij naar de vindplaats te verwijzen."
	},
	{
		id: 4,
		title: "Mogelijk omvangrijk verzoek",
		sentence:
			"Mocht mijn verzoek zodanig omvangrijk zijn dat u het redelijkerwijs niet binnen de wettelijke termijn kunt afronden, dan verzoek ik u mij daar binnen twee weken over te berichten en mij binnen vier weken een index of overzicht te verstrekken van de aangetroffen documenten. Op deze wijze kunnen we in goed onderling overleg een selectie maken van documenten die ik daadwerkelijk wens te ontvangen, alsmede afspraken maken over de volgorde waarin en de termijn of termijnen waarop u deze zult verstrekken. Met deze handreiking hoop ik u onnodig werk te besparen."
	},
	{
		id: 5,
		title: "Bescherming persoonlijke levenssfeer",
		sentence:
			"Indien u tot het oordeel komt dat het belang van bescherming van de persoonlijke levenssfeer integrale openbaarmaking van documenten in de weg staat, verzoek ik subsidiair om openbaarmaking met weglating van de persoonsgegevens, zij het dan uitsluitend van personen die niet uit hoofde van hun functie in de openbaarheid treden. Wanneer u persoonsnamen niet openbaar wilt maken verzoek ik u deze te vervangen door een nummer, zodat de betrokkenheid van eenzelfde persoon bij meerdere aspecten of situaties zichtbaar blijft, ook al is dat dan anoniem."
	},
	{
		id: 6,
		title: "Persoonlijke beleidsopvattingen?",
		sentence:
			"Wanneer u van mening bent dat persoonlijke beleidsopvattingen in documenten opgesteld voor intern beraad integrale openbaarmaking van die documenten in de weg staan, verzoek ik subsidiair om openbaarmaking van de persoonlijke beleidsopvattingen in niet tot de betrokkene herleidbare vorm, en meer subsidiair om openbaarmaking van de feitelijke informatie die geen persoonlijke beleidsopvatting is."
	},
	{
		id: 7,
		title: "Samenvattingen van documenten",
		sentence:
			"Mocht u documenten niet openbaar kunnen maken, dan vraag ik subsidiair om een zakelijke weergave van de inhoud of een samenvatting."
	},
	{
		id: 8,
		title: "Motivering bij weigering",
		sentence:
			"Bij gehele of gedeeltelijke weigering van een document verzoek ik u de weigering per document respectievelijk per alinea te motiveren. Niet alleen met verwijzing naar het betreffende Woo-artikel, maar tevens met onderbouwing waarom die weigeringsgrond zwaarder weegt dan het algemeen belang van openbaarheid."
	},
	{
		id: 9,
		title: "Voorkeur digitale verstrekking",
		sentence:
			"Ik verzoek u de gevraagde informatie die digitaal bij u aanwezig is in digitale vorm te verstrekken. De informatie die alleen analoog (‘hard copy’) in uw archief aanwezig is, ontvang ik bij voorkeur in digitale vorm en anders een kopie. Indien u voornemens bent om hiervoor kosten in rekening te brengen, verzoek ik u vooraf contact met mij op te nemen onder opgave van de hoogte daarvan."
	},
	{
		id: 10,
		title: "Inventarislijst",
		sentence: "Ik verzoek u om als onderdeel van de beslissing een inventarislijst op te nemen."
	}
];
export default DataText;
