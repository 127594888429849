import React from "react";
import bg from "./../../assets/icons/orange-single-line.png";

function HomepageWaarom() {
	return (
		<div className="sectionWaarom">
			<div className="sectionBlock">
				<div>
					<h1 className="scribble" style={{ backgroundImage: `url(${bg})` }}>
						Kico ta un peticion pa divulga documentonan gubernamental y pakico mi lo kier entrega un?
					</h1>
					<p>
						Tur hende tin e derecho pa pidi informacion conteni den documentonan di gobierno. Cada parti di Reino tin un
						ley cu ta regula kiko un petishon asina mester cumpli cune. Peticionnan pa divulga informacion gubernamental ta importnte pasobra gobernacion mes no ta divulga hopi informacion di su mes.
						Entrega un petishon pa divulga informacion gubernamental ta esencial pa pone gobierno rindi cuenta pa e consecuencia di nan maneho, detecta corupcion of descubri malgasto di placa publico.
					</p>
				</div>
				<div>
					<h1 className="scribble" style={{ backgroundImage: `url(${bg})` }}>
						Pakico un generado pa peticion pa divulga documentonan gubernamental?
					</h1>
					<p>
						Bastante periodista, activista, investigado y ciudadano ta kere cu entrega un peticion pa divulga documentonan gubernamental ta un triki legal
						misterioso cu solamente algun insider sa con pa hasi. Esaki ta un exageracion, pero tin algo di berdad tras di dje:
						tin hopi puntu cu bo mester tene na cuenta pa en berdad haya e informacion cu bo ta buscando. Cu nos generado,
						bo no mester preocupa cu bo lo lubida un detaye importante den bo peticion. Laga nos cuminsa!
					</p>
				</div>
			</div>
		</div>
	);
}

export default HomepageWaarom;
